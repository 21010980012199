import styles from "./Modal.module.css";
function Modal( { closeHandling, content } ){
    return <div className={styles.modal} >
        <div className={styles.modalBody}>
            {content}
            <button onClick={closeHandling}> OK </button>
        </div>

    </div>
}

export default Modal