// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Form_formWrapper__mr3AE{
    border: 2px solid #03171A;
    margin-left: 35px;
    margin-right: 35px;
    height: 400px;
    border-radius: 10px;
    padding: 10px;
}

.Form_formWrapper__mr3AE p {
    font-weight: bold;
    font-size: 20px;
}

.Form_formWrapper__mr3AE label{
    font-size: 18px;
}

@media (max-height: 700px) {
    .Form_formWrapper__mr3AE {
        height: 300px;
    }

    .Form_formWrapper__mr3AE label {
        font-size: 12px;
    }
}

@media (min-height: 701px) and (max-height: 750px)  {
    .Form_formWrapper__mr3AE {
        height: 350px;
    }

    .Form_formWrapper__mr3AE label {
        font-size: 12px;
    }
}

@media (max-width: 450px) {
    .Form_formWrapper__mr3AE p {
        font-size: 14px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/layout/Form.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".formWrapper{\n    border: 2px solid #03171A;\n    margin-left: 35px;\n    margin-right: 35px;\n    height: 400px;\n    border-radius: 10px;\n    padding: 10px;\n}\n\n.formWrapper p {\n    font-weight: bold;\n    font-size: 20px;\n}\n\n.formWrapper label{\n    font-size: 18px;\n}\n\n@media (max-height: 700px) {\n    .formWrapper {\n        height: 300px;\n    }\n\n    .formWrapper label {\n        font-size: 12px;\n    }\n}\n\n@media (min-height: 701px) and (max-height: 750px)  {\n    .formWrapper {\n        height: 350px;\n    }\n\n    .formWrapper label {\n        font-size: 12px;\n    }\n}\n\n@media (max-width: 450px) {\n    .formWrapper p {\n        font-size: 14px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formWrapper": `Form_formWrapper__mr3AE`
};
export default ___CSS_LOADER_EXPORT___;
