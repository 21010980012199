import styles from './Login.module.css';
import logo from '../../assets/img/logo.png'
import Form from "../layout/Form";
import FormEmployer from "../forms/forms-impl/FormEmployer";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import { useEffect, useState } from "react";
import FormClockIn from "../forms/forms-impl/FormClockIn";
import FormProofs from "../forms/forms-impl/FormProofs";
// altura:  {height} largura {width} a
function Login() {

    //screen dimensions
    const [height, setHeight]  = useState(0);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        function updateDimensions() {
            setWidth(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);
            setHeight(window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);
        }

        updateDimensions();

        window.addEventListener('resize', updateDimensions);
    }, []);

    //0 for 'empregador'
    //1 for 'registrar ponto'
    //2 for 'comprovantes'
    const[formType, setFormType] = useState(0);

    const handleFormChange = (newFormType) => {
        setFormType(newFormType);
    };

    return (
      <div className={styles.backgroundContainer}>
        <div className={styles.contentWrapper}>
            <div className={styles.sideWrapper}>
                <header className={styles.headerClass}>
                    <img src={logo}  alt={logo} height={"125px"} width={"125px"}/>
                    <div> <span className={styles.spanTittle}> <b> <i> Ponto </i> </b> </span>  Web SGP  </div>

                </header>
                <div className={styles.textTittle}>
                    O <b> PONTO-WEB </b> é um recurso que clientes poderão utilizar para bater o ponto através de <b> qualquer dispositivo </b>
                    que se conecte a <b> qualquer navegador</b>, tornando este processo cada vez mais prático e fácil. Para utilizar o
                    <b> PONTO-WEB</b>, a empresa deve possuir o serviço de <b> SGP PREMIUM</b> contando com essa e outras inúmeras vantagens.
                    <br/>
                    <br/>
                    Selecione a opção desejada:
                </div>
                <div className={styles.options} >
                    <ul>
                        <li onClick={() => handleFormChange(0)}>Empregador</li>
                        <li onClick={() => handleFormChange(1)}>Registrar Ponto</li>
                        <li onClick={() => handleFormChange(2)}>Comprovantes</li>
                    </ul>
                </div>
                {formType === 0 && <Form formImpl={<FormEmployer />} />}
                {formType === 1 && <Form formImpl={<FormClockIn />} />}
                {formType === 2 && <Form formImpl={<FormProofs />} />}
                <footer className={styles.footer}>
                    <p> Gostaria de obter informações sobre os nossos produtos? Visite nosso Site! <a href="http://www.circuitec.com.br"> Circuitec </a></p>
                    <ul className={styles.socialList}>
                        <li> <FaFacebook /></li>
                        <li> <FaInstagram /></li>
                        <li> <FaYoutube /></li>
                    </ul>
                    <p>&copy; <span className={styles.copyrightYear}>2023</span> Circuitec Indústria de Equipamentos Eletrônicos.</p>
                </footer>
                {
                    width < 749 ?
                    <div className={styles.mobileFooter}>
                        <center>
                            <h6>&copy; <span className={styles.copyrightYear}>2023</span> Circuitec Indústria de Equipamentos Eletrônicos.</h6>
                        </center>
                    </div> : <div> </div>
                }
            </div>
            <div className={styles.sideTextWrapper}>
                <h2>
                    <b> <i> <span className={styles.spanName}> Novo SGP </span>  </i> </b>
                    Aproveite ao máximo nossas novas funcionalidades que facilitarão a vida do RH.
                </h2>
            </div>
        </div>
      </div>
    );
}

export default Login