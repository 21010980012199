// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormProofs_proofs__q2WWT {
    font-size: 15px;
    margin-top: 10px;
}
.FormProofs_link__sOo27 {
    text-decoration: none;
    font-style: italic;
}

@media (max-height: 750px){
    .FormProofs_proofs__q2WWT  {
        font-size: 12px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/forms/forms-impl/FormProofs.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".proofs {\n    font-size: 15px;\n    margin-top: 10px;\n}\n.link {\n    text-decoration: none;\n    font-style: italic;\n}\n\n@media (max-height: 750px){\n    .proofs  {\n        font-size: 12px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"proofs": `FormProofs_proofs__q2WWT`,
	"link": `FormProofs_link__sOo27`
};
export default ___CSS_LOADER_EXPORT___;
