// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormEmployer_btnAndForget__LEvwl {
    display: flex;
    flex-direction: column;
}

.FormEmployer_btnAndForget__LEvwl button {
    font-weight: bold;
    background: #03171A;
    color: white;
    margin: 10px 50px;
    cursor: pointer;
    height: 45px;
    border-radius: 10px;
    text-transform: uppercase;
    border: none;
}

.FormEmployer_btnAndForget__LEvwl button:hover{
    color: green;
}

.FormEmployer_btnAndForget__LEvwl a {
    margin-top: 15px;
    font-size: 15px;
    font-style: italic;
    text-decoration: none;
}

@media (max-height: 750px){
    .FormEmployer_btnAndForget__LEvwl button { 
        height: 25px;
        width: 100px;
        border-radius: 5px;
        margin: 5px 5px;
    }
}


@media (max-height: 650px){ 
    .FormEmployer_labelTittle__aTBJB { 
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/forms/forms-impl/FormEmployer.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI;QACI,YAAY;QACZ,YAAY;QACZ,kBAAkB;QAClB,eAAe;IACnB;AACJ;;;AAGA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".btnAndForget {\n    display: flex;\n    flex-direction: column;\n}\n\n.btnAndForget button {\n    font-weight: bold;\n    background: #03171A;\n    color: white;\n    margin: 10px 50px;\n    cursor: pointer;\n    height: 45px;\n    border-radius: 10px;\n    text-transform: uppercase;\n    border: none;\n}\n\n.btnAndForget button:hover{\n    color: green;\n}\n\n.btnAndForget a {\n    margin-top: 15px;\n    font-size: 15px;\n    font-style: italic;\n    text-decoration: none;\n}\n\n@media (max-height: 750px){\n    .btnAndForget button { \n        height: 25px;\n        width: 100px;\n        border-radius: 5px;\n        margin: 5px 5px;\n    }\n}\n\n\n@media (max-height: 650px){ \n    .labelTittle { \n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnAndForget": `FormEmployer_btnAndForget__LEvwl`,
	"labelTittle": `FormEmployer_labelTittle__aTBJB`
};
export default ___CSS_LOADER_EXPORT___;
