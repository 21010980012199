import styles from "./App.css";
import Login from "./components/pages/Login";

function App() {
  return (
      <div className={styles.app}>
        <Login />
      </div>

  );
}

export default App;
