import styles from "./FormProofs.module.css";
function FormProofs(){
    return(
      <div className={styles.proofs}>
          Caro colaborador, para verificar os comprovantes de batida do ponto do <b> Coletor facial </b>,
        estamos disponibilizando a nossa página de comprovantes. Em breve teremos mais novidades.

        <p> Os comprovantes de ponto estão disponíveis através do link <a className={styles.link} href="http://sgpcomprovantes.com.br" > Comprovantes </a>.</p>
      </div>
    );
}

export default FormProofs