import styles from "./FormTextField.module.css";

function DynamicSvg({ svgCode }) {
    return (
        <div className={styles.icon} dangerouslySetInnerHTML={{ __html: svgCode }} />
    );
}

function FormTextField({label, placeholder, type, svg, onChange}){
    return (
        <div className={styles.wrapper}>
            <label> {label} </label>
            <div className={styles.inputIcon}>
                <DynamicSvg svgCode={svg} />
                <input type={type} placeholder={placeholder} onChange={onChange} />
            </div>
        </div>
    );
}

export default FormTextField