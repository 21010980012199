import styles from "./ClockInResponse.module.css";
import okImg from "../../../assets/img/clockin/ok.svg";
import errImg from "../../../assets/img/clockin/err.svg";
function ClockInResponse({ errTxt , proofData, wasOk }) {

    function dateConverter(timestamp) {
        const date = new Date(timestamp);

        const localHours = date.getHours();
        const localMinutes = date.getMinutes();
        const localSeconds = date.getSeconds();

        return `${localHours.toString().padStart(2, '0')}:${localMinutes.toString().padStart(2, '0')}:${localSeconds.toString().padStart(2, '0')}`;

    }

    return <div className={styles.mainResponse}>
        {
            wasOk ?
        <div className={styles.imgTextRow}>
            <img src={okImg} alt={"resultado"} />
            <p> {"Ponto registrado com sucesso"} </p>
        </div> : <div className={styles.imgTextRow}>
                    <img src={errImg} alt={"resultado"} />
                    <p> Falha ao registrar  {errTxt} </p>
                </div>
        }
        {
            wasOk ?
        <div className={styles.proof}>
            <h3> Comprovante da marcação </h3>
            <p> <b> Horário: </b> {dateConverter(proofData['timestamp'])} </p>
            <p> <b> Identificador: </b> {proofData['serialNo']}</p>
            <p> <b> Empresa: </b>  {proofData['enterpriseName']} </p>
            <p> <b> Funcionário: </b> {proofData['employeeName']} </p>
        </div> : <div className={styles.proofErr}>
                    <p> Ocorreu um problema ao bater o ponto, por favor, verifique se: </p>
                    <ul>
                        <li> Credenciais estão corretas; </li>
                        <li> Esta empresa possui pacote Premium válido;</li>
                        <li> Funcionário está habilitado. </li>
                    </ul>
                 </div>
        }
    </div>
}

export default ClockInResponse