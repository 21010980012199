// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormTextField_wrapper__-vI9F {
    display: flex;
    flex-direction: column;
}

.FormTextField_wrapper__-vI9F label {
    margin-bottom: 3px;
    font-weight: bold;
}

.FormTextField_wrapper__-vI9F input {
    background: #ecf0f1;
    margin-bottom: 5px;
    height: 45px;
    border: none;
    width: 100%;
    outline: none;
}

.FormTextField_wrapper__-vI9F input:hover {
    border: none;
}

.FormTextField_inputIcon__qynLZ {
    display: flex;
    flex-direction: row;
    background: #ecf0f1;
}

.FormTextField_icon__rr1Mx {
    margin-top: 10px;
    margin-left: 5px;
}

@media (max-height: 749px){
    .FormTextField_wrapper__-vI9F input {
        height: 25px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/forms/forms-helper/FormTextField.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":[".wrapper {\n    display: flex;\n    flex-direction: column;\n}\n\n.wrapper label {\n    margin-bottom: 3px;\n    font-weight: bold;\n}\n\n.wrapper input {\n    background: #ecf0f1;\n    margin-bottom: 5px;\n    height: 45px;\n    border: none;\n    width: 100%;\n    outline: none;\n}\n\n.wrapper input:hover {\n    border: none;\n}\n\n.inputIcon {\n    display: flex;\n    flex-direction: row;\n    background: #ecf0f1;\n}\n\n.icon {\n    margin-top: 10px;\n    margin-left: 5px;\n}\n\n@media (max-height: 749px){\n    .wrapper input {\n        height: 25px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `FormTextField_wrapper__-vI9F`,
	"inputIcon": `FormTextField_inputIcon__qynLZ`,
	"icon": `FormTextField_icon__rr1Mx`
};
export default ___CSS_LOADER_EXPORT___;
