// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ClockInResponse_mainResponse__8AZDq {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ClockInResponse_imgTextRow__pgbVQ {
    display: flex;
    flex-direction: row;
    alignment-baseline: text-bottom;
    margin-bottom: 15px;
    margin-left: .2em;
}

.ClockInResponse_proof__I6ycl {
    width: 80%;
    font-size: 15px;
    margin-bottom: 10px;
    padding: 10px;
    background: beige;
    border: #03171A 1px solid;
}

.ClockInResponse_proof__I6ycl p {
    font-size: 12px;
    margin-top: -5px;
}

.ClockInResponse_proofErr__6Oh5M {
    padding: 25px;
}

.ClockInResponse_proofErr__6Oh5M li {
    font-size: 15px;
    list-style: none;
}



`, "",{"version":3,"sources":["webpack://./src/components/modal/modal-impl/ClockInResponse.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,+BAA+B;IAC/B,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,eAAe;IACf,mBAAmB;IACnB,aAAa;IACb,iBAAiB;IACjB,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".mainResponse {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.imgTextRow {\n    display: flex;\n    flex-direction: row;\n    alignment-baseline: text-bottom;\n    margin-bottom: 15px;\n    margin-left: .2em;\n}\n\n.proof {\n    width: 80%;\n    font-size: 15px;\n    margin-bottom: 10px;\n    padding: 10px;\n    background: beige;\n    border: #03171A 1px solid;\n}\n\n.proof p {\n    font-size: 12px;\n    margin-top: -5px;\n}\n\n.proofErr {\n    padding: 25px;\n}\n\n.proofErr li {\n    font-size: 15px;\n    list-style: none;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainResponse": `ClockInResponse_mainResponse__8AZDq`,
	"imgTextRow": `ClockInResponse_imgTextRow__pgbVQ`,
	"proof": `ClockInResponse_proof__I6ycl`,
	"proofErr": `ClockInResponse_proofErr__6Oh5M`
};
export default ___CSS_LOADER_EXPORT___;
